import * as React from "react";
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import Image from 'gatsby-image'
import { AnchorLink } from "gatsby-plugin-anchor-links";
// styles
import styled from '@emotion/styled'
import { css } from '@emotion/react'
// slider
import Slider from "react-slick"
// isMobile
import useWidth from './../hooks/useWidth';
//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

import infoContact from "./../data/infoContact.json"



const HeaderCallAction = () => {

    //     const { allStrapiHomePage } = useStaticQuery(graphql`
    //     {
    //         allStrapiHomePage {
    //             nodes {
    //                 telefono
    //             }
    //     }
    //     }
    // `)

    // const { telefono } = allStrapiHomePage.nodes[0]

    const ContainerLogo = styled.div`
        display: flex;
        justify-content: center;
        padding: 1rem;
        height:55px;
        @media(min-width:768px){
            height:60px;
        }
    `

    const ALinkMedia = styled.a`
    color: #99C1DA;
    text-decoration: none;
    :hover{
        color: #6D8AA4;
        transition: color .15s ease-in-out;
    }
    @media(min-width:768px){
        font-size:1.8rem;
    }
`

    const Button = styled.button`
    padding:0.5rem 2rem; 
    border-radius:100px;
    border:0;
    background-color:#FFF;
    color:#FFBD86;
    font-weight:bold;
    a{
        color:#FFBD86;
        :hover{
            color: #ffa153;
        }
    }
    @media(min-width:1200px){
        height:fit-content;
    }
`

    return (

        <div css={css`position:fixed;top:0; width:100%; background-color:#0024B6; z-index:11000;`}>
            <ContainerLogo>
                <Button css={css`margin-right:1rem; padding:0.5rem 2.8rem;`}>
                    {/* <ALinkMedia href={"tel:" + infoContact.whatsapp} rel="noopener noreferrer" target="_blank"> */}
                    <ALinkMedia href={`https://wa.me/${infoContact.whatsapp.replace(/ /g, "")}?text=Buen%20día,%20me%20gustaría%20reservar%20una%20consulta.`} rel="noopener noreferrer" target="_blank">
                        <FontAwesomeIcon icon={faPhone} size="0.5x" />
                        <span css={css`padding-left:2rem;`}>WhatsApp</span>
                    </ALinkMedia>
                </Button>
                <Button>
                    <a href="https://6a0300f1f6a00e61667bfcec4c36bae70f52afe0.agenda.softwaredentalink.com/agendas/agendamiento" target="_blank" title="Reserva" css={css`text-decoration:none; @media(min-width:768px){font-size:1.8rem;}`}>
                        <FontAwesomeIcon icon={faCalendarAlt} size="0.5x" />
                        <span css={css`padding-left:2rem;`}>Reserva Online</span>
                    </a>
                </Button>
            </ContainerLogo>
        </div >
    );
};

export default HeaderCallAction;

