import { useState, useEffect } from 'react';

const useWidth = () => {
    const [viewSize, setViewSize] = useState(null);

    //Use effect to set the size in the first render
    useEffect(() => {
        setViewSize(window.innerWidth);
    }, []);

    //useEffect that render when the window resize
    useEffect(() => {
        const updateWidth = () => {
            setViewSize(window.innerWidth);
        }
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    }, []);

    const isMobile = viewSize <= 992;

    return isMobile;
}

export default useWidth;